// tempexp_35925-file
import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';

import AbstractVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/AbstractVacancy';

const TrlKeys = {
    title: 'newemployer.dashboard.vacancyStub.title',
    descriptionPart1: 'newemployer.dashboard.vacancyStubTemp.description.part1',
    descriptionPart2: 'newemployer.dashboard.vacancyStubTemp.description.part2',
    descriptionMore: 'newemployer.dashboard.vacancyStubTemp.description.more',
    createVacancy: 'newemployer.dashboard.vacancyStubTemp.action.createVacancy',
};

const sendAnalyticsCreateVacancy = () => {
    Analytics.sendHHEventButtonClick('dashboard_vacancy_create_vacancy');
};

// temp_exp_35925_next_line
const sendAnalyticsCreateVacancyArticle = () => {
    Analytics.sendHHEventButtonClick('dashboard_vacancy_first_state_article');
};

const VacancyStubTempexp: TranslatedComponent = ({ trls }) => {
    const vacancyStubRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: 'dashboard_vacancy_first_state',
    });

    // temp_exp_35925_next_line
    const articleLinkShownRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: 'dashboard_vacancy_first_state_article',
    });

    return (
        <AbstractVacancy
            name={trls[TrlKeys.title]}
            description={
                <>
                    <p>{trls[TrlKeys.descriptionPart1]}</p>
                    <VSpacing default={8} />
                    <p>
                        {formatToReactComponent(trls[TrlKeys.descriptionPart2], {
                            '{0}': (
                                <Link
                                    to="/article/vacancies"
                                    ref={articleLinkShownRef}
                                    onClick={sendAnalyticsCreateVacancyArticle}
                                >
                                    {trls[TrlKeys.descriptionMore]}
                                </Link>
                            ),
                        })}
                    </p>
                </>
            }
            narrow
        >
            <div ref={vacancyStubRef} data-qa="dashboard-vacancy-stub">
                <Button
                    size="medium"
                    style="accent"
                    mode="primary"
                    Element={Link}
                    to="/employer/vacancy/create"
                    onClick={sendAnalyticsCreateVacancy}
                    data-qa="dashboard-vacancy-stub__create-vacancy"
                    stretched
                >
                    {trls[TrlKeys.createVacancy]}
                </Button>
            </div>
        </AbstractVacancy>
    );
};

export default translation(VacancyStubTempexp);
