import { useEffect, ReactNode, useMemo } from 'react';

import { Title, useBreakpoint, VSpacing as VSpacingMagritte, Text as TextMagritte } from '@hh.ru/magritte-ui';
import { FormItem } from 'bloko/blocks/form';
import { H2Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import MultiAccountGroupingForm, {
    MultiAccountGroupingFormBaseProps,
} from 'src/components/ApplicantSignInSignUpFlow/MultiAccountGroupingStep/MultiAccountGroupingForm';
import Illustration from 'src/components/IndexPageAnonymous/SignUp/Illustration';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './multi-account-grouping.less';

const TrlKeys = {
    passwordSent: 'registration.registered.passwordSent',
    letterComeSoon: 'registration.registered.letterComeSoon',
    letterMultiaccountNote: 'registration.registered.letterMultiaccountNote',
    letterHighlightedPart: 'registration.registered.letterHighlightedPart',
    letterMultiaccountNoteFull: 'registration.registered.letterMultiaccountNote.full',
};

export interface MultiAccountGroupingStepProps extends MultiAccountGroupingFormBaseProps {
    setHeader: (headerContent: ReactNode) => void;
}

const MultiAccountGroupingStep: TranslatedComponent<MultiAccountGroupingStepProps> = ({
    trls,
    setHeader,
    ...formProps
}) => {
    const { login } = useSelector(({ otp }) => otp);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    const { isGtM, isMobile } = useBreakpoint();

    const headerContent = useMemo(
        () => (
            <>
                <H2Section>
                    {format(trls[TrlKeys.passwordSent], {
                        '{0}': login as string,
                    })}
                </H2Section>
                <VSpacing base={3} />
                <p>
                    {formatToReactComponent(trls[TrlKeys.letterMultiaccountNote], {
                        '{0}': (
                            <Text Element="span" strong>
                                {trls[TrlKeys.letterHighlightedPart]}
                            </Text>
                        ),
                    })}
                </p>
                <p>{trls[TrlKeys.letterComeSoon]}</p>
            </>
        ),
        [login, trls]
    );

    useEffect(() => {
        setHeader(headerContent);
    }, [headerContent, setHeader]);

    if (isAnonymousMagritteExp) {
        return (
            <div className={styles.box}>
                <div className={styles.content}>
                    <Title
                        size={isMobile ? 'small' : 'medium'}
                        Element="h3"
                        description={
                            <>
                                <p>
                                    {formatToReactComponent(trls[TrlKeys.letterMultiaccountNoteFull], {
                                        '{0}': (
                                            <TextMagritte Element="span">
                                                {trls[TrlKeys.letterHighlightedPart]}
                                            </TextMagritte>
                                        ),
                                    })}
                                </p>
                            </>
                        }
                    >
                        {format(trls[TrlKeys.passwordSent], {
                            '{0}': login as string,
                        })}
                    </Title>
                    <VSpacingMagritte default={24} />
                    <MultiAccountGroupingForm
                        render={({ renderInput, renderError, renderSubmit }) => {
                            return (
                                <div className={styles.wrapperMagritte}>
                                    <div className={styles.containerForm}>
                                        {renderInput()}
                                        {renderSubmit()}
                                    </div>
                                    <VSpacingMagritte default={12} />
                                    {renderError()}
                                </div>
                            );
                        }}
                        {...formProps}
                    />
                </div>
                {isGtM && <Illustration size="medium" className={styles.illustration} />}
            </div>
        );
    }

    return (
        <MultiAccountGroupingForm
            render={({ renderInput, renderError, renderSubmit }) => {
                return (
                    <div className={styles.wrapper}>
                        <FormItem>{renderInput()}</FormItem>
                        {renderError()}
                        <VSpacing base={4} />
                        {renderSubmit()}
                    </div>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(MultiAccountGroupingStep);
