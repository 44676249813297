import {
    Component,
    createRef,
    ForwardedRef,
    forwardRef,
    ForwardRefRenderFunction,
    MutableRefObject,
    ReactNode,
} from 'react';
import { connect } from 'react-redux';

import LoadBannerOnVisibleSignature from 'HH/LoadBannerOnVisibleModule.d';
import { AppStore } from 'src/app/store';
import { YandexAdfoxBannerProps } from 'src/models/banners/YandexAdfoxBanner.types';
import { UserTargeting } from 'src/models/userTargeting';

let loadBannerOnVisible: typeof LoadBannerOnVisibleSignature | undefined;

if (!process.env.SSR) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-unsafe-member-access
    loadBannerOnVisible = require('HH/LoadBannerOnVisibleModule').default as typeof LoadBannerOnVisibleSignature;
}

interface BannerProps {
    /** Индификатор баннера */
    id: string;
    /** Название шаблона для баннера */
    templateName?: string;
    /** Нода в которую кладется баннер */
    children?: ReactNode;
    /** Флаг отмены отправки запросов в баннерную систему */
    cancelFetchBanners?: boolean;
    /** Строка текущих get параметров URL */
    search?: string;
    /** Строка текущего URL без параметров */
    pathname?: string;
    /** Параметры для Yandex adfox баннеров */
    adfoxParams?: Omit<YandexAdfoxBannerProps, 'id'>;
    /** Строит пустой див вместо баннера **/
    isEmpty?: boolean;
    userTargeting?: UserTargeting;
    fwdRef?: ForwardedRef<HTMLDivElement>;
    render?: (contentRef: MutableRefObject<HTMLElement | null>) => ReactNode;
}

class BannerRaw extends Component<BannerProps> {
    element: HTMLElement | null = null;
    wrapperRef = createRef<HTMLElement>() as MutableRefObject<HTMLElement | null>;
    abortController: AbortController | null = null;
    static defaultProps = {
        templateName: 'HHC-Banners-Place-Template',
    };

    componentDidMount() {
        this.element = this.props.isEmpty ? null : this.wrapperRef.current;
        if (this.element) {
            this.element.addEventListener('HH-Banners-Init', this.setContainerSizes, { once: true });
            this.initBanners();
        }
    }

    componentDidUpdate() {
        if (this.element) {
            this.initBanners();
        }
    }

    componentWillUnmount() {
        this.abortController?.abort?.();
        if (this.element) {
            this.element.removeEventListener('HH-Banners-Init', this.setContainerSizes);
        }
    }

    shouldComponentUpdate(nextProps: BannerProps) {
        if (nextProps.cancelFetchBanners) {
            return false;
        }
        return nextProps.search !== this.props.search || nextProps.pathname !== this.props.pathname;
    }

    initBanners() {
        const { id, templateName, adfoxParams, userTargeting } = this.props;
        this.abortController?.abort();
        this.abortController = new AbortController();
        if (this.element) {
            this.element.style.height = `${this.element.clientHeight}px`;
            this.element.innerHTML = '';
        }
        let adfoxParamsProperty;
        if (adfoxParams) {
            adfoxParamsProperty = {
                ...adfoxParams,
                type: 'adfox',
                bannerId: id,
                place: id,
                bannerCommonTargeting: userTargeting,
                bannerAdTargeting: adfoxParams.bannerAdTargeting || {},
            };
        }
        const bannerParams = {
            id,
            templateName,
            adfoxParams: adfoxParamsProperty,
        };

        loadBannerOnVisible?.(this.element, bannerParams, this.abortController.signal);
    }

    setContainerSizes = (): void => {
        if (this.element) {
            this.element.style.height = 'auto';
            this.element.style.maxWidth = '100%';
            this.element.style.textAlign = 'center';
        }
    };

    render() {
        if (this.props.render) {
            return this.props.render(this.wrapperRef);
        }
        return (
            <div
                ref={(element) => {
                    if (this.wrapperRef) {
                        this.wrapperRef.current = element;
                    }
                    if (this.props.fwdRef && element) {
                        (this.props.fwdRef as MutableRefObject<HTMLElement>).current = element;
                    }
                }}
            />
        );
    }
}

const Banner: ForwardRefRenderFunction<HTMLDivElement, BannerProps> = (props, ref) => (
    <BannerRaw {...props} fwdRef={ref} />
);

export default connect(
    (state: AppStore) => ({
        pathname: state.router.location.pathname,
        search: state.router.location.search,
        cancelFetchBanners: state.cancelFetchBanners,
        userTargeting: state.userTargeting,
    }),
    null,
    null,
    { forwardRef: true }
)(forwardRef(Banner));
