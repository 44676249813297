import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Card } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmptySearches from 'src/components/IndexPageEmployer/components/LastSearches/EmptySearches';
import SearchList from 'src/components/IndexPageEmployer/components/LastSearches/SearchList';

const LastSearches = ({ maxHeight }) => {
    const ref = useRef(null);
    const lastSearches = useSelector((state) => state.employerLastSearches);
    const hasItems = lastSearches.length > 0;

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'last_searches',
                type: lastSearches.length ? 'searches' : 'empty',
            });
        }
    }, [lastSearches.length]);

    return (
        <div
            ref={ref}
            className={classNames('last-searches-wrapper', {
                'last-searches-wrapper_not-empty': hasItems,
            })}
            style={hasItems && maxHeight ? { maxHeight: `${maxHeight}px` } : undefined}
        >
            <Card showBorder padding={24} borderRadius={24} stretched verticalStretched>
                {lastSearches.length ? <SearchList searches={lastSearches} /> : <EmptySearches />}
            </Card>
        </div>
    );
};

LastSearches.propTypes = {
    trls: PropTypes.object,
    maxHeight: PropTypes.number,
};

export default translation(LastSearches);
