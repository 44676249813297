import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Card, Divider, Title } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

import Body from 'src/components/IndexPageEmployer/components/Updates/Body';
import Footer from 'src/components/IndexPageEmployer/components/Updates/Footer';
import Tabs from 'src/components/IndexPageEmployer/components/Updates/Tabs';
import { TAB_IDS } from 'src/components/IndexPageEmployer/components/Updates/constants';

const Updates = ({ trls, maxHeight }) => {
    const updatesRef = useRef();
    const [activeTabId, setActiveTab] = useState(TAB_IDS.NEW_TAB_ID);

    const switchTab = (tabId) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        Analytics.sendHHEventElementShown(updatesRef.current, {
            name: 'employer_updates_info',
        });
    }, []);

    return (
        <div ref={updatesRef} className="updates-wrapper" style={maxHeight ? { height: `${maxHeight}px` } : undefined}>
            <Card showBorder padding={24} borderRadius={24} stretched verticalStretched>
                <div className="updates-scrollable-container">
                    <Title Element="h2" size="small" data-qa="updates-title">
                        {trls[Updates.trls.header]}
                    </Title>

                    <Tabs activeTabId={activeTabId} onChange={switchTab} />

                    <div className="updates-divider-wrapper">
                        <Divider marginBottom={16} />
                    </div>

                    <Body activeTabId={activeTabId} />

                    <Footer />
                </div>
            </Card>
        </div>
    );
};

Updates.propTypes = {
    trls: PropTypes.object,
    maxHeight: PropTypes.number,
};

Updates.trls = {
    header: 'index.employerUpdatesInfo.header',
};

export default translation(Updates);
