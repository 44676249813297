import { FC } from 'react';
import classnames from 'classnames';

import { GridRow, GridColumn, GridLayout } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import DashboardBigPromoFooter from 'src/components/IndexPageAnonymous/DashboardBigPromoFooter';
import DashboardFooter from 'src/components/IndexPageAnonymous/DashboardFooter';
import DashboardSearch from 'src/components/IndexPageAnonymous/DashboardSearch';

const Dashboard: FC = () => {
    const dashboard = useSelector(({ supernovaAnonymousDashboard }) => supernovaAnonymousDashboard);
    const userType = useSelector(({ userType }) => userType);
    const isOnboardingExp = useSelector(({ anonymousOnboarding }) => anonymousOnboarding.isOnboardingExp);
    const isRedesignAnonymousMenuExpB = useExperiment('redesign_anonymous_menu_v2_b', true, false);
    const isRedesignAnonymousMenuExpC = useExperiment('redesign_anonymous_menu_v2_c', true, false);
    const isRedesignAnonymousMenuExpD = useExperiment('redesign_anonymous_menu_v2_d', true, false);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    const isRedesignActive =
        userType === UserType.Anonymous &&
        (isRedesignAnonymousMenuExpB || isRedesignAnonymousMenuExpC || isRedesignAnonymousMenuExpD);

    const magritteContent = () => {
        return (
            <GridLayout>
                <GridRow>
                    <GridColumn xs={0} s={8} m={8} flexibleContent>
                        <DashboardSearch />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn xs={0} s={8} m={1} xxl={0} />
                    <GridColumn xs={0} s={8} m={11} xxl={12} flexibleContent>
                        {dashboard.bigPromoEmployerId ? <DashboardBigPromoFooter /> : <DashboardFooter />}
                    </GridColumn>
                </GridRow>
            </GridLayout>
        );
    };

    const blokoContent = () => (
        <ColumnsWrapper>
            <Column xs="0" s="0" m={isOnboardingExp ? '1' : '0'} l={isOnboardingExp ? '3' : '2'} />
            <Column xs="0" s="8" m="10" l="10">
                <DashboardSearch />
            </Column>
            <Column xs="0" s="8" m="12" l="16">
                {dashboard.bigPromoEmployerId ? <DashboardBigPromoFooter /> : <DashboardFooter />}
            </Column>
        </ColumnsWrapper>
    );

    return (
        <div
            className={classnames('supernova-dashboard supernova-dashboard_optimized-menu', {
                'supernova-dashboard_optimized-menu-redesign': isRedesignActive,
                [`supernova-dashboard_bg-applicant-${dashboard.backgroundScope}-${dashboard.backgroundIndex}`]:
                    dashboard.backgroundScope,
                [`supernova-dashboard_big-promo-${dashboard.bigPromoEmployerId}`]: dashboard.bigPromoEmployerId,
                'supernova-dashboard_backoffice': userType === UserType.BackOffice,
                'supernova-dashboard-magritte': isAnonymousMagritteExp,
                'supernova-dashboard-magritte-new-header': isRedesignActive,
            })}
        >
            <div
                className={classnames('supernova-dashboard-content', {
                    'supernova-dashboard-anon-magritte': isAnonymousMagritteExp,
                })}
            >
                {isAnonymousMagritteExp ? magritteContent() : blokoContent()}
            </div>
        </div>
    );
};

export default Dashboard;
