const COUNTER_ROUND = 1000;

export const MAX_VACANCIES_AMOUNT = 3;

export const formatCounter = (responses: number): string => {
    if (responses > COUNTER_ROUND) {
        return `+${Math.floor(responses / COUNTER_ROUND)}K`;
    }

    return `+${responses}`;
};

export const getFooterLinkUrl = ({
    hasActive,
    hasArchive,
    hasDraft,
}: {
    hasActive: boolean;
    hasArchive: boolean;
    hasDraft: boolean;
}): string => {
    if (hasActive) {
        return '/employer/vacancies';
    }

    if (hasArchive) {
        return '/employer/vacancies/archived';
    }

    if (hasDraft) {
        return '/employer/vacancies/drafts';
    }

    return '/employer/vacancies';
};
