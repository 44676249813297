import { useMemo, useState } from 'react';

import { Card, Divider, Tab, Tabs, Title, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FAQ from 'src/components/FAQ';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import CalendarContent from 'src/components/IndexPageEmployer/components/Blog/CalendarContent';
import NewsOrArticlesContent from 'src/components/IndexPageEmployer/components/Blog/NewsOrArticlesContent';

import styles from './styles.less';

enum TabEnum {
    NEWS = 'news',
    ARTICLES = 'articles',
    CALENDAR = 'calendar',
    FAQ = 'faq',
}

const BLOCK_LINKS: Record<TabEnum, string> = {
    [TabEnum.NEWS]: '/articles/site-news',
    [TabEnum.ARTICLES]: '/articles/employers',
    [TabEnum.CALENDAR]: '/articles/events',
    [TabEnum.FAQ]: 'https://feedback.hh.ru/',
};

const TrlKeys = {
    title: 'index.blog.title',
    feed: 'index.feed.title',
    [TabEnum.NEWS]: 'index.blog.news.title',
    [TabEnum.ARTICLES]: 'index.blog.articles.title',
    [TabEnum.CALENDAR]: 'index.blog.calendar.title',
    [TabEnum.FAQ]: 'index.blog.faq.title',
};

const Blog: TranslatedComponent<{ isVacanciesWidgetExp?: boolean; faqMaxItems?: number }> = ({
    trls,
    isVacanciesWidgetExp,
    faqMaxItems,
}) => {
    const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.NEWS);

    const employerNews = useSelector((state) => state.employerNews);
    const employerArticles = useSelector((state) => state.employerArticles);
    const { eventsMain } = useSelector((state) => state.employerIndexPage.oldEmployer) || { eventsMain: null };
    const employerFaq = useSelector((state) => state.employerFaq);
    const activityItems = useMemo(() => eventsMain?.body?.events || [], [eventsMain?.body?.events]);

    const tabList = useMemo(() => {
        const list: TabEnum[] = [TabEnum.NEWS, TabEnum.ARTICLES];

        if (activityItems.length) {
            list.push(TabEnum.CALENDAR);
        }

        return isVacanciesWidgetExp && employerFaq.length ? [...list, TabEnum.FAQ] : list;
    }, [activityItems.length, employerFaq.length, isVacanciesWidgetExp]);

    const content = useMemo(() => {
        const href = BLOCK_LINKS[activeTab];

        switch (activeTab) {
            case TabEnum.ARTICLES:
                return <NewsOrArticlesContent isArticle href={href} items={employerArticles} />;
            case TabEnum.NEWS:
                return <NewsOrArticlesContent href={href} items={employerNews} />;
            case TabEnum.CALENDAR:
                return <CalendarContent items={activityItems} href={href} />;
            case TabEnum.FAQ:
                return <FAQ items={employerFaq} isVacanciesWidgetExp={isVacanciesWidgetExp} maxCount={faqMaxItems} />;
            default:
                return null;
        }
    }, [activeTab, employerArticles, employerNews, activityItems, employerFaq, isVacanciesWidgetExp, faqMaxItems]);

    return (
        <div className={styles.blogCardWrapper}>
            <Card showBorder padding={24} borderRadius={24} stretched verticalStretched>
                <div className={styles.blogCardContent}>
                    <Title Element="h2" size="small" data-qa="blog-title">
                        {isVacanciesWidgetExp ? trls[TrlKeys.feed] : trls[TrlKeys.title]}
                    </Title>
                    <Tabs
                        data-qa="blog-tabs"
                        activeItemId={activeTab}
                        onChange={(tabId) => setActiveTab(tabId as TabEnum)}
                        mode="secondary"
                    >
                        {tabList.map((tab) => {
                            return (
                                <Tab data-qa={`${tab}-tab`} key={tab} id={tab}>
                                    {trls[TrlKeys[tab]]}
                                </Tab>
                            );
                        })}
                    </Tabs>
                    <Divider />
                    <VSpacing default={24} />
                    {content}
                </div>
            </Card>
        </div>
    );
};

export default translation(Blog);
