import { FC } from 'react';
import classNames from 'classnames';

import { MAX_VACANCIES_AMOUNT } from 'src/components/EmployerVacanciesWidget/utils';
import { useSelector } from 'src/hooks/useSelector';

import CreateVacancyCell from 'src/components/EmployerVacanciesWidget/components/CreateVacancyCell';
import NoVacanciesPlaceholder from 'src/components/EmployerVacanciesWidget/components/NoVacanciesPlaceholder';
import Vacancy from 'src/components/EmployerVacanciesWidget/components/Vacancy';

import styles from './vacancy.less';

const Vacancies: FC = () => {
    const { vacancies } = useSelector((state) => state.vacanciesWidget);

    if (vacancies.length === 0) {
        return <NoVacanciesPlaceholder />;
    }

    return (
        <ul className={classNames({ [styles.listStretched]: vacancies.length > 1 })}>
            {vacancies.map((vacancy) => (
                <Vacancy key={vacancy.id} vacancy={vacancy} />
            ))}

            {vacancies.length < MAX_VACANCIES_AMOUNT && <CreateVacancyCell />}
        </ul>
    );
};

export default Vacancies;
