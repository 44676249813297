import { useEffect, ReactNode } from 'react';

import { Title, useBreakpoint, VSpacing as VSpacingMagritte } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdvertisementAgreement from 'src/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import useShowAdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/useShowAdvertisementSubscriptionApplicant';
import SignUpForm, { SignUpSignFormBaseProps } from 'src/components/ApplicantSignInSignUpFlow/SignUpStep/SignUpForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './sign-up-step.less';

const TrlKeys = {
    header1: 'signUpStep.header.1',
    header2: 'signUpStep.header.2',
};

export interface SignUpStepProps extends SignUpSignFormBaseProps {
    setHeader: (headerContent: ReactNode) => void;
}

const SignUpStep: TranslatedComponent<SignUpStepProps> = ({ trls, setHeader, ...formProps }) => {
    const showAdvertisementAgreement = useShowAdvertisementAgreement();
    const showAdvertisementSubscriptionApplicant = useShowAdvertisementSubscriptionApplicant();
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    const { isGtS, isMobile } = useBreakpoint();

    useEffect(() => {
        setHeader(
            <Text size={TextSize.Large} strong>
                {trls[TrlKeys.header1]}
                <br />
                {trls[TrlKeys.header2]}
            </Text>
        );
    }, [setHeader, trls]);

    if (isAnonymousMagritteExp) {
        return (
            <div className={styles.content}>
                <Title size={isMobile ? 'small' : 'medium'} Element="h3">
                    {trls[TrlKeys.header1]} {trls[TrlKeys.header2]}
                </Title>
                <VSpacingMagritte default={24} />
                <SignUpForm
                    render={({
                        renderFirstNameField,
                        renderLastNameField,
                        renderAccountDeleteInactive,
                        renderSubmit,
                        renderAdvertisementAgreement,
                        renderAdvertisementSubscriptionApplicant,
                    }) => {
                        return (
                            <div>
                                <div className={styles.container}>
                                    {renderFirstNameField({ size: 'medium' })}
                                    {renderLastNameField({ size: 'medium' })}
                                    {isGtS && <div className={styles.submit}>{renderSubmit()}</div>}
                                </div>
                                <div>
                                    {/* Для РБ */}
                                    {renderAccountDeleteInactive()}
                                    {/* Для РФ */}
                                    {showAdvertisementSubscriptionApplicant && (
                                        <>
                                            <VSpacingMagritte default={24} />
                                            {renderAdvertisementSubscriptionApplicant()}
                                        </>
                                    )}
                                    {/* Для РБ */}
                                    {showAdvertisementAgreement && (
                                        <>
                                            <VSpacingMagritte default={24} />
                                            {renderAdvertisementAgreement()}
                                        </>
                                    )}
                                    {!isGtS && (
                                        <>
                                            <VSpacingMagritte default={24} />
                                            <div className={styles.submit}>{renderSubmit()}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                    backurl="/applicant/resumes/new"
                    isSignupMain
                    {...formProps}
                />
            </div>
        );
    }

    return (
        <SignUpForm
            render={({
                renderFirstNameField,
                renderLastNameField,
                renderAccountDeleteInactive,
                renderSubmit,
                renderAdvertisementAgreement,
                renderAdvertisementSubscriptionApplicant,
            }) => {
                return (
                    <>
                        <div className={styles.wrapper}>
                            {renderFirstNameField()}
                            <VSpacing base={2} />
                            {renderLastNameField()}
                            <VSpacing base={4} />
                            {renderAccountDeleteInactive()}
                            {showAdvertisementSubscriptionApplicant && (
                                <>
                                    {renderAdvertisementSubscriptionApplicant()}
                                    <VSpacing base={6} />
                                </>
                            )}
                            {renderSubmit()}
                            {showAdvertisementAgreement && (
                                <div>
                                    <Column l="5" m="5" s="5" xs="4" container>
                                        <VSpacing base={5} />
                                        {renderAdvertisementAgreement()}
                                    </Column>
                                </div>
                            )}
                        </div>
                    </>
                );
            }}
            backurl="/applicant/resumes/new"
            isSignupMain
            {...formProps}
        />
    );
};

export default translation(SignUpStep);
