import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import { useIsRussia } from 'src/hooks/useCountries';
import useExperiment from 'src/hooks/useExperiment';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import CareerBanner from 'src/components/IndexPageApplicant/Actions/CareerBanner';
import CreateResume from 'src/components/IndexPageApplicant/Actions/CreateResume';
import EnableResumeAutoUpdate from 'src/components/IndexPageApplicant/Actions/EnableResumeAutoUpdate';
import KakdelaBanner from 'src/components/IndexPageApplicant/Actions/KakdelaBanner';
import ResponseStreak from 'src/components/IndexPageApplicant/Actions/ResponseStreak';
import UpdateResumes from 'src/components/IndexPageApplicant/Actions/UpdateResumes';

import styles from './actions.less';

interface ActionsProps {
    hasResumes: boolean;
    hasFinishedResumes: boolean;
}

const Actions: FC<ActionsProps> = ({ hasResumes, hasFinishedResumes }) => {
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();
    const isCareerExp = useExperiment('web_applicant_career_banner');

    return (
        <div>
            {!hasResumes && <VSpacing default={28} xs={0} />}
            <div className={styles.cardsScroller}>
                <div className={styles.cardsWrapper}>
                    {!hasResumes && <CreateResume />}
                    {hasFinishedResumes && (
                        <>
                            <UpdateResumes />
                            <EnableResumeAutoUpdate />
                        </>
                    )}
                    {!isZP && isRussia && <KakdelaBanner />}
                    {hasResumes && <ResponseStreak />}
                    {isCareerExp && <CareerBanner />}
                </div>
            </div>
        </div>
    );
};

export default Actions;
