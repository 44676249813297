// tempexp_27495_file
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import VerificationForm, { VerificationFormBaseProps } from 'src/components/Verification/VerificationForm';
import translation from 'src/components/translation';

import styles from './verification-step.less';

const TrlKeys = {
    header: 'signUpForm.verification.header',
    phone: {
        sent: 'signUpForm.verification.codeSent.phone',
        description1: 'signUpForm.verification.codeSentDescription.1.phone',
        description2: 'signUpForm.verification.codeSentDescription.2.phone',
    },
    email: {
        sent: 'signUpForm.verification.codeSent.email',
        description: 'otp.confirm.sub.header',
    },
    whatsApp: {
        sent: 'signUpForm.verification.codeSent.whatsApp',
    },
};

const getHeader = (authType?: string) => {
    switch (authType) {
        case 'SMS':
            return TrlKeys.phone.sent;
        case 'WHATS_APP':
            return TrlKeys.whatsApp.sent;
        default:
            return TrlKeys.email.sent;
    }
};

export interface VerificationStepProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
}

const VerificationStep: TranslatedComponent<VerificationStepProps> = ({
    trls,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    ...formProps
}) => {
    const { authType, login } = formProps;

    return (
        <VerificationForm
            render={({ renderInput, renderCodeError, renderCodeSender, renderSubmit }) => {
                return (
                    <div>
                        <>
                            <Text size={TextSize.Large} strong>
                                {trls[TrlKeys.header]}
                            </Text>
                            <VSpacing base={1} />
                            <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                                <p>
                                    {formatToReactComponent(trls[getHeader(authType)], {
                                        '{0}': <strong>{login}</strong>,
                                    })}
                                </p>
                                <VSpacing base={4} />
                                {authType === 'SMS' ? (
                                    <>
                                        <p>{trls[TrlKeys.phone.description1]}</p>
                                        <p>{trls[TrlKeys.phone.description2]}</p>
                                    </>
                                ) : (
                                    <p>{trls[TrlKeys.email.description]}</p>
                                )}
                            </Text>
                        </>
                        <VSpacing base={4} />
                        <div className={styles.formContainer}>
                            <div className={styles.inputWrapper}>
                                {renderInput({
                                    placeholder: codePlaceholder,
                                    isAutofocus: true,
                                })}
                            </div>
                            {renderCodeError()}
                            <VSpacing base={6} />
                            <div className={styles.buttonWrapper}>{renderSubmit({ isStretched: true })}</div>
                        </div>
                        <div>
                            <VSpacing base={3} />
                            <Text importance={TextImportance.Tertiary}>{renderCodeSender()}</Text>
                        </div>
                    </div>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(VerificationStep);
