import { Fragment } from 'react';
import classnames from 'classnames';

import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import ConversionNumber from 'bloko/blocks/conversion';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { DisplayType } from 'src/models/displayType';
import { GlobalStatisticsCategory } from 'src/models/globalStatistics';

const TrlKeys = {
    'counter.resumes.one': 'main.counter.resumes.one',
    'counter.resumes.some': 'main.counter.resumes.some',
    'counter.resumes.many': 'main.counter.resumes.many',
    'counter.resumes.zero': 'main.counter.resumes.zero',
    'counter.vacancies.one': 'main.counter.vacancies.one',
    'counter.vacancies.some': 'main.counter.vacancies.some',
    'counter.vacancies.many': 'main.counter.vacancies.many',
    'counter.vacancies.zero': 'main.counter.vacancies.zero',
    'counter.employers.one': 'main.counter.employers.one',
    'counter.employers.some': 'main.counter.employers.some',
    'counter.employers.many': 'main.counter.employers.many',
    'counter.employers.zero': 'main.counter.employers.zero',
};

const DashboardFooter: TranslatedComponent = ({ trls }) => {
    const isDesktop = useSelector((state) => state.displayType === DisplayType.Desktop);
    const showAppLinks = !(useIsZarplataPlatform() && isDesktop);
    const globalStatistics = useSelector(({ globalStatistics }) => globalStatistics);
    const stayInTouch = useSelector(({ stayInTouch }) => stayInTouch);
    const showResumeStats = !useIsRabotaBy();

    return (
        <div className="supernova-dashboard-footer">
            {globalStatistics !== null &&
                [
                    GlobalStatisticsCategory.Resumes,
                    GlobalStatisticsCategory.Vacancies,
                    GlobalStatisticsCategory.Employers,
                ].map((category, index) => {
                    const value =
                        category === GlobalStatisticsCategory.Resumes && !showResumeStats
                            ? null
                            : globalStatistics[category];

                    if (!value) {
                        return null;
                    }
                    return (
                        <Fragment key={category}>
                            <div
                                className={classnames('supernova-dashboard-stats', {
                                    'supernova-dashboard-stats_secondary': index === 2,
                                })}
                            >
                                <div className="supernova-dashboard-stats__value">
                                    {NumberFormatter.format(value.toString())}
                                </div>
                                <Text>
                                    <ConversionNumber
                                        many={trls[TrlKeys[`counter.${category}.many`]]}
                                        some={trls[TrlKeys[`counter.${category}.some`]]}
                                        one={trls[TrlKeys[`counter.${category}.one`]]}
                                        zero={trls[TrlKeys[`counter.${category}.zero`]]}
                                        hasValue={false}
                                        value={value}
                                    />
                                </Text>
                            </div>
                        </Fragment>
                    );
                })}
            <div className="supernova-dashboard-footer-fill" />
            {showAppLinks && (
                <div className="supernova-dashboard-footer-app-buttons">
                    <div className="supernova-app-buttons">
                        {stayInTouch.apps
                            .filter((app) => app.name !== 'mobile')
                            .map((app) => {
                                const href = stayInTouch.mobilePage
                                    ? `${stayInTouch.mobilePage}?from=main_head&hhtmFromLabel=main_head`
                                    : app.href;
                                return (
                                    <Link
                                        className={`supernova-app-button supernova-app-button_${app.name}`}
                                        to={href}
                                        target="_blank"
                                        rel="noreferrer"
                                        key={app.name}
                                    />
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default translation(DashboardFooter);
