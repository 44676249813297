import { HSpacing, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';
import { VacanciesWidgetVacancy } from 'src/models/employer/vacanciesWidget';

import VacancyButton from 'src/components/EmployerVacanciesWidget/components/VacancyButton';

import styles from './vacancy.less';

const TrlKeys = {
    expires: 'employer.indexPage.widgets.myVacancies.vacancy.expires',
    draft: 'employer.indexPage.widgets.myVacancies.vacancy.draft',
    archive: 'employer.indexPage.widgets.myVacancies.vacancy.archive',
};

interface VacancyProps {
    vacancy: VacanciesWidgetVacancy;
}

const Vacancy: TranslatedComponent<VacancyProps> = ({ trls, vacancy }) => {
    const isDraft = vacancy.type === 'draft';
    const isArchive = vacancy.type === 'archive';
    const draftUrl = `/employer/vacancy/create?draftId=${vacancy.id}`;
    const vacancyUrl = `/vacancy/${vacancy.id}`;

    return (
        <li className={styles.vacancy}>
            <SPALink
                data-qa="my-vacancies_vacancy-link"
                to={isDraft ? draftUrl : vacancyUrl}
                className={styles.vacancyContent}
            >
                <Text typography="label-2-regular">{vacancy.title}</Text>

                <VSpacing default={4} />

                <div className={styles.vacancyDescription}>
                    {vacancy.area?.name && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text typography="label-3-regular" style="secondary">
                                {vacancy.area.name}
                            </Text>
                        </span>
                    )}

                    {vacancy.expireTime && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text
                                typography="label-3-regular"
                                style={vacancy.isProlongateHighlight ? 'negative' : 'secondary'}
                            >
                                {format(trls[TrlKeys.expires], {
                                    '{0}': formatDate(vacancy.expireTime, 'd MMMM'),
                                })}
                            </Text>
                        </span>
                    )}

                    {isDraft && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text typography="label-3-regular" style="warning">
                                {trls[TrlKeys.draft]}
                            </Text>
                        </span>
                    )}

                    {isArchive && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text typography="label-3-regular" style="negative">
                                {trls[TrlKeys.archive]}
                            </Text>
                        </span>
                    )}
                </div>
            </SPALink>

            <VSpacing default={6} gteM={0} />
            <HSpacing default={0} gteM={24} />

            <div className={styles.vacancyButtonWrapper}>
                <VacancyButton vacancy={vacancy} isArchive={isArchive} isDraft={isDraft} draftUrl={draftUrl} />
            </div>
        </li>
    );
};

export default translation(Vacancy);
