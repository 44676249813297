import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';
import careerBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/main/career_banner_element_shown';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import Card, { CardType } from 'src/components/IndexPageApplicant/Actions/Card';

const TrlKeys = {
    title: 'index.applicant.actions.careerBanner.title',
    update: 'index.applicant.actions.careerBanner.link',
};

const CareerBanner: TranslatedComponent = ({ trls }) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const handleClick = () => {
        buttonClick({ buttonName: 'career_skills_growth' });
        window.open(`https://career.ru/?hhtmFrom=${hhtmSource}`, '_blank');
    };

    return (
        <ElementShownAnchor fn={careerBannerElementShown}>
            <Card
                dataQa="applicant-index-nba-action_career-banner"
                caption={trls[TrlKeys.title]}
                linkText={trls[TrlKeys.update]}
                type={CardType.CareerBanner}
                onClick={handleClick}
            />
        </ElementShownAnchor>
    );
};

export default translation(CareerBanner);
