import { FC } from 'react';
import classNames from 'classnames';

import capitalize from 'bloko/common/capitalize';

import styles from './styles.less';

interface IllustrationProps {
    size: 'small' | 'medium' | 'large';
    className?: string;
}

const Illustration: FC<IllustrationProps> = ({ size, className }) => {
    return <div className={classNames(styles.illustration, className, styles[`illustration${capitalize(size)}`])} />;
};

export default Illustration;
