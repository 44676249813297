import { GridColumn, GridRow, GridLayout, VSpacing } from '@hh.ru/magritte-ui';

import FAQ from 'src/components/FAQ';
import { useIsRussia } from 'src/hooks/useCountries';
import useExperiment from 'src/hooks/useExperiment';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import AdditionalServices from 'src/components/IndexPageEmployer/components/AdditionalServices';
import Announcement from 'src/components/IndexPageEmployer/components/Announcement';
import Blog from 'src/components/IndexPageEmployer/components/Blog';
import Dashboard from 'src/components/IndexPageEmployer/components/Dashboard';
import NewLayout from 'src/components/IndexPageEmployer/components/Layout';

const ARTICLES_EMPLOYER_MAX_COUNT = 6;

const IndexEmployer = () => {
    const isVacanciesWidgetExpB = useExperiment('employer_vacancies_widget_b');
    const isVacanciesWidgetExpC = useExperiment('employer_vacancies_widget_c');
    const isVacanciesWidgetExp = isVacanciesWidgetExpB || isVacanciesWidgetExpC;

    const employerFaq = useSelector((state) => state.employerFaq);
    const isZp = useIsZarplataPlatform();
    const isRussia = useIsRussia();

    if (isVacanciesWidgetExp) {
        return (
            <>
                <NewLayout />
                <Announcement />
            </>
        );
    }

    return (
        <>
            <GridLayout>
                <Dashboard />
                {!isZp && (
                    <>
                        <GridRow>
                            <GridColumn xs={4} s={8} m={isRussia ? 8 : 12} l={6} flexibleContent>
                                <Blog />
                            </GridColumn>
                            <GridColumn flexibleContent xs={0} s={0} m={0} l={6}>
                                <FAQ verticalStretched maxCount={ARTICLES_EMPLOYER_MAX_COUNT} items={employerFaq} />
                            </GridColumn>
                            <GridColumn flexibleContent xs={0} s={0} m={4} l={0}>
                                <VSpacing default={isRussia ? 24 : 0} m={0} />
                                <AdditionalServices />
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn xs={4} s={8} m={12} l={0}>
                                <VSpacing default={24} />
                                <FAQ maxCount={ARTICLES_EMPLOYER_MAX_COUNT} items={employerFaq} />
                            </GridColumn>
                        </GridRow>
                        <VSpacing default={24} />
                    </>
                )}
                <Announcement />
            </GridLayout>
        </>
    );
};

export default IndexEmployer;
