import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { TopLevelSite } from 'src/models/topLevelSite';

import styles from './styles.less';

const TrlKeys = {
    header: 'index.headers.main',
    headerRuBy: 'index.headers.main.jobForEveryone',
};

const DashboardSearchTitle: TranslatedComponent = ({ trls }) => {
    const isZp = useIsZarplataPlatform();
    const { h1 } = useSelector(({ pageMetaData }) => pageMetaData);
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);

    if (isZp) {
        return <h1 className={styles.title}>{h1 || trls[TrlKeys.header]}</h1>;
    }
    return (
        <h3 className={styles.title}>
            {[TopLevelSite.RU, TopLevelSite.BY].includes(topLevelSite)
                ? trls[TrlKeys.headerRuBy]
                : trls[TrlKeys.header]}
        </h3>
    );
};

export default translation(DashboardSearchTitle);
