import Analytics from '@hh.ru/analytics-js';
import { Button, HSpacing } from '@hh.ru/magritte-ui';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { formatCounter } from 'src/components/EmployerVacanciesWidget/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacanciesWidgetVacancy } from 'src/models/employer/vacanciesWidget';

const TrlKeys = {
    showVacancy: 'employer.indexPage.widgets.myVacancies.vacancy.showVacancy',
    responses: 'employer.indexPage.widgets.myVacancies.vacancy.responses',
    draftContinue: 'employer.indexPage.widgets.myVacancies.vacancy.draftContinue',
    archivePublish: 'employer.indexPage.widgets.myVacancies.vacancy.archivePublish',
};

interface VacancyButtonProps {
    vacancy: VacanciesWidgetVacancy;
    isDraft: boolean;
    isArchive: boolean;
    draftUrl: string;
}

const VacancyButton: TranslatedComponent<VacancyButtonProps> = ({ vacancy, trls, isArchive, isDraft, draftUrl }) => {
    const { activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);

    const vacancyResponsesUrl = `/employer/vacancyresponses?vacancyId=${vacancy.id}`;
    const archiveUrl = `/employer/vacancy/restore/${vacancy.id}`;

    const handleVacancyClick = (type: string) => {
        Analytics.sendHHEventButtonClick('vacancies_widget_vacancy', {
            activeVacanciesAmount,
            type,
        });
    };

    if (vacancy.unread?.responses) {
        return (
            <Button
                style="positive"
                mode="secondary"
                Element={SPALink}
                to={vacancyResponsesUrl}
                stretched
                onClick={() => handleVacancyClick('responses')}
            >
                {trls[TrlKeys.responses]}
                <HSpacing default={8} />
                {formatCounter(vacancy.unread.responses)}
            </Button>
        );
    }

    if (isDraft) {
        return (
            <Button
                style="neutral"
                mode="secondary"
                Element={SPALink}
                to={draftUrl}
                stretched
                onClick={() => handleVacancyClick('draft')}
            >
                {trls[TrlKeys.draftContinue]}
            </Button>
        );
    }

    if (isArchive) {
        return (
            <Button
                style="neutral"
                mode="secondary"
                Element={SPALink}
                to={archiveUrl}
                stretched
                onClick={() => handleVacancyClick('archive')}
            >
                {trls[TrlKeys.archivePublish]}
            </Button>
        );
    }

    return (
        <Button
            style="neutral"
            mode="secondary"
            Element={SPALink}
            to={vacancyResponsesUrl}
            stretched
            onClick={() => handleVacancyClick('show')}
        >
            {trls[TrlKeys.showVacancy]}
        </Button>
    );
};

export default translation(VacancyButton);
