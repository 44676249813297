import { FC, useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';

import Footer from 'src/components/EmployerVacanciesWidget/components/Footer';
import Header from 'src/components/EmployerVacanciesWidget/components/Header';
import Vacancies from 'src/components/EmployerVacanciesWidget/components/Vacancies';

import styles from './styles.less';

const EmployerVacanciesWidget: FC = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { vacancies, activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);

    const renderFooter = () => {
        const shouldRenderAdditionalPlaceholder = vacancies.length === 1;

        if (vacancies.length === 0) {
            return null;
        }

        return (
            <div className={styles.growingFooter}>
                <Footer />
                {shouldRenderAdditionalPlaceholder && <div className={styles.cat} />}
            </div>
        );
    };

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'vacancies_widget',
                activeVacanciesAmount,
            });
        }
    }, [activeVacanciesAmount]);

    return (
        <Card showBorder borderRadius={24} stretched verticalStretched ref={ref}>
            <div className={styles.widgetContent}>
                <Header />
                <Vacancies />
                {renderFooter()}
            </div>
        </Card>
    );
};

export default EmployerVacanciesWidget;
