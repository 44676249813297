import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button, HSpacing, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import styles from './vacancy.less';

const TrlKeys = {
    title: 'employer.indexPage.widgets.myVacancies.createVacancyCell.title',
    description: 'employer.indexPage.widgets.myVacancies.createVacancyCell.description',
    button: 'employer.indexPage.widgets.myVacancies.createVacancyCell.button',
};

const CreateVacancyCell: TranslatedComponent = ({ trls }) => {
    const { activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);
    const isZp = useIsZarplataPlatform();

    const handleCreateVacancyClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('vacancies_widget_create_vacancy', {
            activeVacanciesAmount,
            hhtmSourceLabel: 'create-vacancy-cell',
        });
    }, [activeVacanciesAmount]);

    return (
        <li className={styles.vacancy}>
            <div className={styles.vacancyContent}>
                <Text typography="label-3-regular">{trls[TrlKeys.title]}</Text>

                <VSpacing default={4} />

                <Text typography="label-3-regular" style="secondary">
                    {trls[TrlKeys.description]}
                </Text>
            </div>

            <VSpacing default={6} gteM={0} />
            <HSpacing default={0} gteM={24} />

            <div className={styles.vacancyButtonWrapper} onClick={handleCreateVacancyClick}>
                <Button
                    style={isZp ? 'neutral' : 'accent'}
                    mode="secondary"
                    Element={SPALink}
                    to="/employer/vacancy/create"
                    stretched
                >
                    {trls[TrlKeys.button]}
                </Button>
            </div>
        </li>
    );
};

export default translation(CreateVacancyCell);
