import { Component, createRef } from 'react';
import { AxiosError } from 'axios';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonKind } from 'bloko/blocks/button';
import ControlGroup, { ControlGroupItem } from 'bloko/blocks/controlGroup';
import Tip, { TipTheme, TipPlacement } from 'bloko/blocks/drop/Tip';
import { FormItem, FormLegend } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import InputText, { InputType } from 'bloko/blocks/inputText';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import { TranslationHOCProps } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

enum ResponseCodes {
    Success = 'SUCCESS',
    Error = 'ERROR',
    ErrorLimit = 'ERROR_LIMIT',
    ErrorLimitPhone = 'ERROR_LIMIT_PHONE',
    ErrorLimitIP = 'ERROR_LIMIT_IP',
}

const TrlKeys = {
    label: 'employer.thankYou.getAppSMS.label',
    submit: 'employer.thankYou.getAppSMS.submit',
    empty: 'employer.thankYou.getAppSMS.error.empty',
    SUCCESS: 'employer.thankYou.getAppSMS.response.SUCCESS',
    ERROR: 'employer.thankYou.getAppSMS.response.ERROR',
    ERROR_LIMIT: 'employer.thankYou.getAppSMS.response.ERROR_LIMIT',
    ERROR_LIMIT_PHONE: 'employer.thankYou.getAppSMS.response.ERROR_LIMIT_PHONE',
    ERROR_LIMIT_IP: 'employer.thankYou.getAppSMS.response.ERROR_LIMIT_IP',
    invalid: 'employer.thankYou.getAppSMS.error.invalid',
    common: 'employer.thankYou.getAppSMS.error.common',
};

declare global {
    interface FetcherPostApi {
        '/get_app_sms': {
            body: {
                phone: string;
                from?: string;
            };
            queryParams: void;
            response: { code: ResponseCodes };
        };
    }
}

interface GetAppSMSProps {
    /** Поле для отслеживания конверсии */
    analyticsFrom?: string;
    placeholder?: string;
    label?: string;
    gapBottom?: boolean;
    // tempexp_27846_start
    sendAnalytics?: boolean;
    vacancyId?: string;
    employerManagerId?: string;
    // tempexp_27846_end
}

interface GetAppSMSState {
    phone: string;
    showTooltip: boolean;
    tooltipText: string;
    tooltipTheme: TipTheme;
    loading: boolean;
}

/**
 * Повторяет работу HH/GetAppSMS.js
 */
class GetAppSMS extends Component<TranslationHOCProps & GetAppSMSProps, GetAppSMSState> {
    static defaultProps = {
        analyticsFrom: '',
        placeholder: '+7 900 944 9490',
        gapBottom: false,
    };

    tipActivatorRef = createRef<HTMLInputElement>();

    state = {
        phone: '',
        showTooltip: false,
        tooltipText: '',
        tooltipTheme: TipTheme.Attention,
        loading: false,
    };

    showTooltip = (tooltipText: string, tooltipTheme: TipTheme) => {
        this.setState({
            showTooltip: true,
            tooltipText,
            tooltipTheme,
        });
    };

    hideTooltip = () => {
        this.setState({
            showTooltip: false,
        });
    };

    setLoading = (loading: boolean) => {
        this.setState({
            loading,
        });
    };

    getSMSLink = () => {
        const phone = this.state.phone.trim();
        if (phone === '') {
            this.showTooltip(this.props.trls[TrlKeys.empty], TipTheme.Attention);
        } else {
            this.setLoading(true);

            // tempexp_27846_start
            const { sendAnalytics, vacancyId, employerManagerId } = this.props;

            if (sendAnalytics) {
                Analytics.sendHHEventButtonClick('get_sms_link', { vacancyId, employerManagerId });
            }
            // tempexp_27846_end

            fetcher
                .postFormData(
                    '/get_app_sms',
                    Object.assign(
                        { phone },
                        this.props.analyticsFrom
                            ? { from: this.props.analyticsFrom, hhtmFromLabel: this.props.analyticsFrom }
                            : {}
                    )
                )
                .then(
                    (response) => {
                        const messageType =
                            response.data.code === ResponseCodes.Success ? TipTheme.Dark : TipTheme.Attention;
                        this.showTooltip(this.props.trls[TrlKeys[response.data.code]], messageType);
                        this.setLoading(false);
                    },
                    (error: AxiosError) => {
                        const message = this.props.trls[TrlKeys[error.response?.status === 400 ? 'invalid' : 'common']];
                        this.showTooltip(message, TipTheme.Attention);
                        this.setLoading(false);
                    }
                );
        }
    };

    render() {
        const { trls, gapBottom, label } = this.props;

        return (
            <Gap bottom={gapBottom}>
                <div className="get-app-sms-label-wrapper">
                    <FormItem>
                        <FormLegend>{label || trls[TrlKeys.label]}</FormLegend>
                    </FormItem>
                </div>
                <div className="get-app-sms-form-wrapper">
                    <ControlGroup autosize>
                        <ControlGroupItem main>
                            <Tip
                                show={this.state.showTooltip}
                                theme={this.state.tooltipTheme}
                                placement={TipPlacement.Top}
                                render={() => this.state.tooltipText}
                                onExternalClose={this.hideTooltip}
                                activatorRef={this.tipActivatorRef}
                            >
                                <InputText
                                    value={this.state.phone}
                                    onChange={(value) => {
                                        this.hideTooltip();
                                        this.setState({ phone: value });
                                    }}
                                    type={InputType.Text}
                                    placeholder={this.props.placeholder}
                                    disabled={this.state.loading}
                                    ref={this.tipActivatorRef}
                                />
                            </Tip>
                        </ControlGroupItem>
                        <ControlGroupItem>
                            <Button
                                onClick={this.getSMSLink}
                                kind={ButtonKind.Success}
                                loading={
                                    this.state.loading && (
                                        <Loading initial={LoadingColor.Green60} scale={LoadingScale.Small} />
                                    )
                                }
                            >
                                {trls[TrlKeys.submit]}
                            </Button>
                        </ControlGroupItem>
                    </ControlGroup>
                </div>
            </Gap>
        );
    }
}

export default translation(GetAppSMS);
