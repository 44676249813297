import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { GridColumn, GridRow, VSpacing } from '@hh.ru/magritte-ui';

import AdditionalServices from 'src/components/IndexPageEmployer/components/AdditionalServices';
import Blog from 'src/components/IndexPageEmployer/components/Blog';
import EmployerEventList from 'src/components/IndexPageEmployer/components/EmployerEventList';
import LastSearches from 'src/components/IndexPageEmployer/components/LastSearches';
import Updates from 'src/components/IndexPageEmployer/components/Updates';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

const DEFAULT_WIDGET_HEIGHT = 430;

const Dashboard = () => {
    const isZp = useIsZarplataPlatform();
    const eventsRef = useRef(null);
    const isRussia = useIsRussia();
    const bannersRef = useRef(null);
    const [widgetHeight, setWidgetHeight] = useState(DEFAULT_WIDGET_HEIGHT);

    const updateWidgetHeight = useCallback(() => {
        const eventsRect = eventsRef.current?.getBoundingClientRect();
        const bannersRect = bannersRef.current?.getBoundingClientRect();

        const eventsHeight = eventsRect?.height || 0;
        const bannersHeight = bannersRect?.height || 0;

        if (eventsHeight || bannersHeight) {
            setWidgetHeight(Math.max(eventsHeight, bannersHeight));
        }
    }, []);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            // force reflow
            // eslint-disable-next-line babel/no-unused-expressions
            bannersRef.current?.offsetHeight;
            updateWidgetHeight();
        });

        try {
            if (bannersRef.current) {
                observer.observe(bannersRef.current, {
                    attributes: true,
                    childList: true,
                    subtree: true,
                    attributeFilter: ['style'],
                });
            }
        } catch (error) {
            console.error(error);
        }

        return () => {
            observer.disconnect();
        };
    }, [updateWidgetHeight]);

    useLayoutEffect(() => {
        updateWidgetHeight();
    }, [updateWidgetHeight]);

    return (
        <>
            <VSpacing default={40} />

            <GridRow rowGap={24}>
                <GridColumn xs={4} s={8} m={6} l={3} flexibleContent>
                    <EmployerEventList ref={eventsRef} />
                </GridColumn>

                {!isZp && isRussia ? (
                    <GridColumn flexibleContent xs={4} s={8} m={0}>
                        <AdditionalServices />
                    </GridColumn>
                ) : null}

                <GridColumn xs={4} s={8} m={6} l={3} flexibleContent>
                    <LastSearches maxHeight={widgetHeight} />
                </GridColumn>
                {isZp ? (
                    <GridColumn xs={4} s={8} m={12} l={6}>
                        <Blog />
                    </GridColumn>
                ) : (
                    <>
                        <GridColumn xs={4} s={8} m={12} l={isRussia ? 3 : 6} flexibleContent>
                            <Updates maxHeight={widgetHeight} />
                        </GridColumn>
                        {isRussia && (
                            <GridColumn flexibleContent xs={0} s={0} m={0} l={3}>
                                <div ref={bannersRef} className={styles.additionalServices}>
                                    <AdditionalServices />
                                </div>
                            </GridColumn>
                        )}
                    </>
                )}
            </GridRow>
            <VSpacing default={24} l={24} />
        </>
    );
};

export default Dashboard;
